import React from "react"
import SectionHeader from "../../components/_layout/section-header"
import { Box } from "rebass"
import Layout from "../../components/_layout"
import Divider from "../../components/divider"
import Container from "../../components/_layout/container"
import Pattern from "../../components/pattern"
import { css } from "emotion"
import SEO from "../../components/seo"

const ContactUs = () => {
  return (
    <Layout forceOpaqueHeader>
      <SEO
        title="Contact us"
        description="Do you wish to contact us? Find here all the ways to do it!"
      />
      <SectionHeader title="Contact us" />
      <Pattern opacity={0.2} />
      <Box style={{marginBottom: '3px solid gray'}}>
        <Divider
          firstLine="Hi, nice to meet you: we are Spinup! If you wish to have more info about our services or you want to get in touch with us for any topic, you are in the right place."
          secondLine="How may we help you?"
        />
      </Box>
      <Container size="small">
        <iframe
          title="chatbot"
          className={css`
            width: 100%;
            height: 500px;
          `}
          scrolling="no"
          src="https://chats.spinup.media/c/site-contact-us"
        />
      </Container>
    </Layout>
  )
}

export default ContactUs
